import React from "react";
import { useMediaQuery as useMediaQueryReact } from "react-responsive";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Inputs from "./Inputs";
import Outputs from "./Outputs";
import PieChart from "./viz/PieChart";
import BarChart from "./viz/BarChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Suggestions from "./Suggestions";

import { calcRemaningMonths, calcMinPayment } from "./debtCalculator";

const useStyles = makeStyles(() => ({
    paper: {
        height: 780,
        width: 950,
        marginTop: 25,
        marginBottom: 25
    },
    smallPaper: {
        height: 780,
        width: 350,
        marginTop: 25,
        marginBottom: 25
    },
    disclaimer: {
        padding: 10,
        fontSize: 12
    }
}));

export default function DebtCal() {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        amount: "",
        interest: "",
        payment: "",
        months: "",
        interestPaid: "",
        interestAmounts: []
    });

    const getOutputs = values => {
        const { amount, interest, payment, months } = values;
        if (payment !== "") {
            const res = calcRemaningMonths(
                parseFloat(amount),
                parseFloat(interest),
                parseFloat(payment)
            );
            setValues({
                amount,
                interest,
                payment,
                months: res.months,
                interestPaid: res.interestPaid,
                interestAmounts: res.interestAmounts
            });
        } else {
            const res = calcMinPayment(
                parseFloat(amount),
                parseFloat(interest),
                parseInt(months)
            );
            setValues({
                amount,
                interest,
                months,
                payment: res.payment,
                interestPaid: res.interestPaid,
                interestAmounts: res.interestAmounts
            });
        }
    };

    const isDesktopOrLaptop = useMediaQueryReact({
        query: "(min-device-width: 1224px)"
    });

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const descriptionColor = prefersDarkMode ? "white" : "black";

    return isDesktopOrLaptop ? (
        <Grid container justify="center" spacing={3}>
            <Grid key="inputs" item>
                <Paper className={classes.smallPaper}>
                    <Typography
                        align="left"
                        variant="subtitle1"
                        style={{
                            padding: 20,
                            paddingBottom: 0,
                            color: descriptionColor
                        }}
                    >
                        The <strong>Debt Repayment Calculator</strong> will show
                        you how long it will take to pay off your debt balance.
                        Choose from making the minimum payment or a time when
                        you would prefer to be debt free.
                    </Typography>
                    <Inputs
                        getOutputs={getOutputs}
                        prefersDarkMode={prefersDarkMode}
                    />
                </Paper>
            </Grid>
            <Grid
                key="outputs"
                item
                className={classes.paper}
                style={{ maxHeight: "100%", overflow: "auto" }}
            >
                <Paper>
                    <Outputs {...values} prefersDarkMode={prefersDarkMode} />
                </Paper>
                <Paper>
                    <PieChart
                        {...values}
                        prefersDarkMode={prefersDarkMode}
                        isDesktopOrLaptop={isDesktopOrLaptop}
                    />
                    <BarChart {...values} prefersDarkMode={prefersDarkMode} />
                </Paper>
                <Suggestions
                    prefersDarkMode={prefersDarkMode}
                    isDesktopOrLaptop={isDesktopOrLaptop}
                />
                <Typography
                    align="left"
                    className={classes.disclaimer}
                    style={{ color: descriptionColor }}
                >
                    Disclaimer: The information provided here is for general
                    informational purposes only. All information on the Site is
                    provided in good faith, however, we make no representation
                    or warranty of any kind, express or implied, regarding the
                    accuracy, adequacy, validity, reliability of any information
                    on the Site.
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <>
            <div
                style={{
                    padding: 25,
                    paddingBottom: 0,
                    color: descriptionColor
                }}
            >
                The <strong>Debt Repayment Calculator</strong> will show you how
                long it will take to pay off your debt balance. Choose from
                making the minimum payment or a time when you would prefer to be
                debt free.
            </div>
            <Inputs getOutputs={getOutputs} prefersDarkMode={prefersDarkMode} />

            <Paper style={{ marginBottom: 10 }}>
                <Outputs {...values} prefersDarkMode={prefersDarkMode} />
                <PieChart
                    {...values}
                    prefersDarkMode={prefersDarkMode}
                    isDesktopOrLaptop={isDesktopOrLaptop}
                />
                <BarChart {...values} prefersDarkMode={prefersDarkMode} />
            </Paper>
            <Suggestions
                prefersDarkMode={prefersDarkMode}
                isDesktopOrLaptop={isDesktopOrLaptop}
            />
            <Typography
                align="left"
                className={classes.disclaimer}
                style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: descriptionColor
                }}
            >
                Disclaimer: The information provided here is for general
                informational purposes only. All information on the Site is
                provided in good faith, however, we make no representation or
                warranty of any kind, express or implied, regarding the
                accuracy, adequacy, validity, reliability of any information on
                the Site.
            </Typography>
        </>
    );
}
