import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    formStyles: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(2)
    },
    disabled: {
        background: "gray"
    }
}));
export default function Inputs(props) {
    const classes = useStyles();

    const [values, setValues] = React.useState({
        amount: "",
        interest: "",
        payment: "",
        months: ""
    });

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = event => {
        window.scrollTo(0, 600);
        event.preventDefault();
        props.getOutputs(values);
    };

    const color = props.prefersDarkMode && "pink";

    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <FormControl
                fullWidth
                className={classes.formStyles}
                variant="outlined"
            >
                <InputLabel
                    htmlFor="outlined-adornment-amount"
                    style={{ color: color }}
                >
                    Amount
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    autoFocus
                    value={values.amount}
                    onChange={handleChange("amount")}
                    endAdornment={
                        <InputAdornment position="end">$</InputAdornment>
                    }
                    labelWidth={60}
                    type="number"
                    color="secondary"
                    inputProps={{ min: 0, step: "0.01" }}
                    required
                />
            </FormControl>
            <FormControl
                fullWidth
                className={classes.formStyles}
                variant="outlined"
            >
                <InputLabel
                    htmlFor="outlined-adornment-interest"
                    style={{ color: color }}
                >
                    Interest Rate
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-interest"
                    value={values.interest}
                    onChange={handleChange("interest")}
                    endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                    }
                    labelWidth={100}
                    type="number"
                    inputProps={{ step: "0.01", min: 0, max: 100 }}
                    color="secondary"
                    required
                />
            </FormControl>
            <FormControl
                fullWidth
                className={classes.formStyles}
                variant="outlined"
            >
                <InputLabel
                    htmlFor="outlined-adornment-payment"
                    style={{ color: color }}
                >
                    Expected Monthly Payment
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-payment"
                    value={values.payment}
                    onChange={handleChange("payment")}
                    endAdornment={
                        <InputAdornment position="end">$</InputAdornment>
                    }
                    labelWidth={200}
                    disabled={values.months !== ""}
                    required={
                        values.months !== "" ||
                        (values.payment === "" && values.months === "")
                    }
                    color="secondary"
                    type="number"
                    inputProps={{ min: 0, step: "0.01" }}
                />
            </FormControl>
            OR
            <FormControl
                fullWidth
                className={classes.formStyles}
                variant="outlined"
            >
                <InputLabel
                    htmlFor="outlined-adornment-months"
                    style={{ color: color }}
                >
                    Expected Payoff Time
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-months"
                    value={values.months}
                    onChange={handleChange("months")}
                    endAdornment={
                        <InputAdornment position="end">months</InputAdornment>
                    }
                    labelWidth={160}
                    disabled={values.payment !== ""}
                    required={
                        values.payment !== "" ||
                        (values.payment === "" && values.months === "")
                    }
                    color="secondary"
                    type="number"
                    inputProps={{ min: 0 }}
                />
            </FormControl>
            <Button
                className={classes.button}
                style={
                    props.prefersDarkMode
                        ? {
                              backgroundColor: "pink"
                          }
                        : {}
                }
                variant={props.prefersDarkMode ? "contained" : "outlined"}
                color={!props.prefersDarkMode ? "secondary" : ""}
                type="submit"
            >
                Calculate
            </Button>
        </form>
    );
}
