import React from "react";
import { Pie } from "react-chartjs-2";

export default function PieChart(props) {
    const data = {
        labels: ["Interest", "Principle"],
        datasets: [
            {
                data: [
                    Math.round(props.interestPaid),
                    Math.round(props.amount)
                ],
                backgroundColor: ["pink", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["pink", "#36A2EB", "#FFCE56"]
            }
        ]
    };
    const titleColor = props.prefersDarkMode ? "pink" : "rgba(0, 0, 0, 0.6)";
    const legendText = props.prefersDarkMode && {
        labels: { fontColor: "white" }
    };

    const graphSize = props.isDesktopOrLaptop ? 500 : 400;

    return (
        <div style={{ width: graphSize, padding: "auto", margin: "auto" }}>
            {props.months !== -1 &&
                props.months !== "" && (
                    <Pie
                        data={data}
                        options={{
                            title: {
                                display: true,
                                text: "Debt Repayment Chart",
                                fontSize: 20,
                                fontColor: titleColor
                            },
                            legend: {
                                display: true,
                                position: "bottom",
                                ...legendText
                            }
                        }}
                    />
                )}
        </div>
    );
}
