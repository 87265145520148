import React, { useEffect } from "react";
import ReactGA from "react-ga";
import "./App.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import DebtCal from "./deb-cal/DebtCal";

function App() {
    useEffect(() => {
        ReactGA.initialize("UA-106753629-3");
        ReactGA.pageview(window.location.pathname);
    }, []);

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? "dark" : "light"
                }
            }),
        [prefersDarkMode]
    );

    return (
        <ThemeProvider theme={theme}>
            <div
                className="App"
                style={{
                    backgroundColor: theme.palette.background.default,
                    overflowX: "hidden"
                }}
            >
                <DebtCal />
            </div>
        </ThemeProvider>
    );
}

export default App;
