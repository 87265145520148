import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "pink",
        fontSize: 18,
        fontWeight: "bold"
    },
    body: {
        fontSize: 20
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const fullColumns = [
    { id: "name", label: "App", minWidth: 170 },
    { id: "cost", label: "Cost", minWidth: 100 },
    {
        id: "description",
        label: "Description",
        minWidth: 170
    },
    {
        id: "bestFor",
        label: "Best for",
        minWidth: 170
    }
];
const mobileColumns = [
    { id: "name", label: "App", minWidth: 170 },
    {
        id: "description",
        label: "Description",
        minWidth: 170
    }
];

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginTop: 20,
        margin: "auto"
    },
    container: {
        height: 480
    }
});

export default function StickyHeadTable(props) {
    const classes = useStyles();

    const linkColor = props.prefersDarkMode && "pink";

    const createData = (name, cost, description, bestFor, link) => {
        const linkedName = (
            <Typography variant={props.isDesktopOrLaptop ? "h6" : "subtitle1"}>
                <Link
                    style={{ color: linkColor }}
                    href={link}
                    target="_blank"
                    rel="noopener"
                >
                    {name}
                </Link>
            </Typography>
        );
        return { name: linkedName, cost, description, bestFor };
    };
    const columns = props.isDesktopOrLaptop ? fullColumns : mobileColumns;
    const rows = [
        createData(
            "Robinhood",
            "Free",
            "Robinhood provides free stock, options, ETF and cryptocurrency trades with a $0 account minimum.",
            "Investing",
            "http://join.robinhood.com/oanhn"
        ),
        createData(
            "Personal Capital",
            "Free",
            "A personal financial software that keeps track of all your accounts in one location. Personal Capital creates summaries of your spending, net worth and investment portfolio. There is also a retirement calculator.",
            "Data-driven budgeting",
            "https://share.personalcapital.com/x/DH0Go1"
        ),
        createData(
            "SoFi",
            "Free",
            "A strong option for good-credit consumers, offering low rates, no fees and flexible payments.",
            "Budgeting, investing, banking, loan",
            "https://sofi.com/share/2864840?src=copy"
        ),
        createData(
            "Mint",
            "Free",
            "An all-in-one budget app. Monitor cash flow and savings, set up and track individual budgets for different spending categories.",
            "Budgeting",
            "https://www.mint.com/"
        ),
        createData(
            "Coinbase",
            "Free",
            "A secure platform that makes it easy to buy, sell, earn and store cryptocurrency like Bitcoin, Ethereum, and more.",
            "Crypto investing",
            "https://www.coinbase.com/join/nguyen_37o"
        ),
        createData(
            "Simple",
            "Free",
            "A 100% online bank without hassle.",
            "Banking",
            "https://www.simple.com/"
        )
    ];

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => {
                            return (
                                <StyledTableRow
                                    // hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.name}
                                >
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.format &&
                                                typeof value === "number"
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
