import React from "react";
import numeral from "numeral";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import Alert from "@material-ui/lab/Alert";
import { calcMinPayment } from "./debtCalculator";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary
    },
    hr: {
        border: "1px dotted pink"
    }
}));

export default function Outputs(props) {
    const classes = useStyles();
    const examplePayment = calcMinPayment(
        parseFloat(props.amount),
        parseFloat(props.interest),
        36
    );
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-device-width: 1224px)"
    });
    return (
        <div className={classes.root}>
            {props.months === -1 ? (
                <Alert severity="error" variant="filled">
                    Monthly payment is too low. For example, the minimum payment
                    on a 36 month plan would be&nbsp;
                    {numeral(examplePayment.payment).format("$ 0,0")}.
                </Alert>
            ) : (
                <Grid container>
                    {isDesktopOrLaptop ? (
                        <>
                            <Grid item xs={2}>
                                <Typography component={"span"} gutterBottom>
                                    <Box fontWeight="fontWeightBold" m={1}>
                                        Balance
                                    </Box>
                                </Typography>
                                <Divider variant="middle" />
                                <div>
                                    {numeral(props.amount).format("$ 0,0")}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography component={"span"} gutterBottom>
                                    <Box fontWeight="fontWeightBold" m={1}>
                                        {isDesktopOrLaptop
                                            ? "Interest Rate"
                                            : "Rate"}
                                    </Box>
                                </Typography>
                                <Divider variant="middle" />
                                <div>
                                    {numeral(props.interest / 100).format(
                                        "0.00%"
                                    )}
                                </div>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={isDesktopOrLaptop ? 4 : 4} autoFocus>
                        <Typography component={"span"} gutterBottom>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {isDesktopOrLaptop
                                    ? "Total interest paid"
                                    : "Interest paid"}
                            </Box>
                        </Typography>
                        <Divider variant="middle" />
                        <strong>
                            {numeral(props.interestPaid).format("$ 0,0")}
                        </strong>
                    </Grid>
                    <Grid item xs={isDesktopOrLaptop ? 2 : 4}>
                        <Typography component={"span"} gutterBottom>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {isDesktopOrLaptop
                                    ? "Monthly Payment"
                                    : "Payment"}
                            </Box>
                        </Typography>
                        <Divider variant="middle" />
                        <strong>
                            {numeral(props.payment).format("$ 0,0")}
                        </strong>
                    </Grid>
                    <Grid item xs={isDesktopOrLaptop ? 2 : 4}>
                        <Typography component={"span"} gutterBottom>
                            <Box fontWeight="fontWeightBold" m={1}>
                                {isDesktopOrLaptop ? "Payoff Time" : "Time"}
                            </Box>
                        </Typography>
                        <Divider variant="middle" />
                        {props.months === -1 || !props.months ? (
                            <RemoveOutlinedIcon />
                        ) : (
                            <strong>{props.months} mos.</strong>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
