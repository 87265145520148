import React from "react";
import { Line } from "react-chartjs-2";
import { useMediaQuery } from "react-responsive";

export default function BarChart(props) {
    const months = props.months
        ? Array.from({ length: props.months }, (_, index) => index + 1)
        : Array.from({ length: 36 }, (_, index) => index + 1);

    const colors = months.map(() => "#FF6384");

    const data = {
        labels: months,
        datasets: [
            {
                data: props.interestAmounts,
                hoverBackgroundColor: colors,
                borderColor: props.prefersDarkMode ? "pink" : "#FF6384",
                borderWidth: 0,
                pointRadius: 1
            }
        ]
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-device-width: 1224px)"
    });

    const titleColor = props.prefersDarkMode ? "pink" : "rgba(0, 0, 0, 0.6)";

    const axisConfig = label => {
        return props.prefersDarkMode
            ? {
                  scaleLabel: {
                      display: true,
                      labelString: label,
                      fontColor: "white",
                      fontSize: 16
                  },
                  gridLines: {
                      display: false
                  },
                  ticks: {
                      fontColor: "white"
                  }
              }
            : {
                  scaleLabel: {
                      display: true,
                      labelString: label,
                      fontSize: 16
                  }
              };
    };
    return (
        <div
            style={{
                width: isDesktopOrLaptop ? 700 : 350,
                margin: "auto",
                marginBottom: 10,
                marginTop: 10
            }}
        >
            {props.months !== -1 &&
                props.months !== "" && (
                    <Line
                        data={data}
                        options={{
                            title: {
                                display: true,
                                text: "Total Interest Paid Over Time",
                                fontSize: 20,
                                fontColor: titleColor
                            },
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [axisConfig("$")],
                                xAxes: [axisConfig("Months")]
                            }
                        }}
                    />
                )}
        </div>
    );
}
