export function calcRemaningMonths(amount, interest, payment) {
    // N = -log(1 – (Ai / P)) / log (1 + i)
    const i = interest / (12 * 100);
    const A = amount;
    const P = payment;
    const N = Math.round(-Math.log(1 - (A * i) / P) / Math.log(1 + i));
    if (isNaN(N)) {
        return {
            months: -1,
            interestPaid: -1
        };
    }

    const interestResults = amortizedInterestPaid(A, P, i);
    return {
        months: N,
        interestPaid: interestResults.interestPaid,
        interestAmounts: interestResults.interestAmounts
    };
}

export function calcMinPayment(amount, interest, months) {
    // P = Ai / (1 – (1 + i)-N)
    const i = interest / (12 * 100);
    const A = amount;
    const N = months;
    const P = (A * i) / (1 - Math.pow(1 + i, -N));
    const interestResults = amortizedInterestPaid(A, P, i);

    return {
        payment: P,
        interestPaid: interestResults.interestPaid,
        interestAmounts: interestResults.interestAmounts
    };
}

function amortizedInterestPaid(A, P, i) {
    let interestAmounts = [];
    let interestPaid = 0;
    let lastBalance = A;
    while (lastBalance > 0) {
        interestAmounts.push(Math.round(interestPaid));
        interestPaid += lastBalance * i;
        lastBalance -= P - lastBalance * i;
    }
    return {
        interestPaid: interestPaid,
        interestAmounts: interestAmounts
    };
}
